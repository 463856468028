<!--
#***********************************************
#
#      Filename: src/views/index.vue
#
#        Author: wwj - 318348750@qq.com
#       Company: 甘肃国臻物联网科技有限公司
#   Description: 首页
#        Create: 2021-08-18 22:12:58
# Last Modified: 2021-11-15 16:38:38
#***********************************************
-->
<template>
  <div class="app-container home">工作台</div>
</template>

<script>
  export default {
    name: 'Index'
  }
</script>
